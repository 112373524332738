.game-g {
    max-width: 880px;
    margin: 0 auto;
    padding-top: 4em;
  }
.h1-g {
    text-align: center;
    padding: 0em 4em;

}
.button-g {
    display: block;
    margin: 1em auto;
    background: none;
    padding: 6px 12px;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    font-size: 1em;
    transition: opacity 250ms ease-out;
}
.button-g:hover {
    opacity: 0.8;
}
.card-grid {
    width: 90%;
    margin: 3em auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
}
.card-c{
    margin: 0em auto;
    position: relative;
    width: 100px;
    height: 100px;
    cursor: pointer;
}
.card-c .cartoon {
    display: block;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    border-radius: 6px;
}
.card-c .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;
}
.flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
}
.card-c .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
}
.flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
}

.result {
    text-align: center;
    padding: 2em 0;
    font-weight: bold;

}
@media screen and (min-width: 880px) {
    .card-c{
        position: relative;
        width: 200px;
        height: 200px;
        cursor: pointer;
    }
    .card-c .cartoon {
        display: block;
        width: 200px;
        height: 200px;
        border: 2px solid #fff;
        border-radius: 6px;
    }
    .card-grid {
        width: 880px;
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 20px;
    }
}