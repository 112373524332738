.toggle-container {
    margin: 0 auto;

    padding-top: 1em; 
    padding-left: 5em;
    padding-bottom: 0.6em;

}
.toggle {
    margin: 0 auto;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background-color: var(--color-background);
    box-shadow: 0 2px 5px var(--color-container);
    position: relative;
    cursor: pointer;
}
.act {
    background-color: #161a26;
}
.act > .notch {
    transform: translate(25px, 0);
    background-color: var(--color-green);
}
.notch{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: var(--color-blue);
    position: absolute;
    top: 1px;
    left: 1px;
    box-shadow: 0 0 3px var(--color-container) ;
    transition: all 0.3s ease;
}
.day {
    position: absolute;
    top: 4px;
    left: 3px;
    color: rgb(215, 246, 255);
}
.night {
    position: absolute;
    top: 5px;
    left: 28px;
    color: rgb(237, 252, 26);
}