.info-container {
    margin: 3em auto;
    max-width: 1400px;
}
.container-youtube{
    margin: 2em auto;
    max-width: 1400px;
}
iframe {
    display: block;
    margin: 0 auto;
    width: 360px;
    height: 200px;
}

.container-youtube > h2 {
    text-align: center;
    margin: 1em 0 1.2em 0;
}
.quick {
    position: fixed; 
    top: 0em;
    left: 0.5em;
}
.block1 {
    max-width: 900px;
    display: grid;
    grid-template-columns: 1fr;
}
.block2 {
    margin: 4em auto 2em;
}
.block2 > h4 {
    padding-bottom: 0.2em;
}
.block {
    width: 350px;
    margin: 0 auto;
}
.block > img {
    transform: rotate(-10deg);
    animation: example 12s infinite;
    width: 300px;
    height: 300px;
}
.cText {
    margin: 2em auto;
    padding-left: 1em;
}
.small-bio {
    font-size: small;
    width: 80%;
    padding: 1em 0;
}
.activities {
    font-weight: bold;
    font-size: 13px;
    padding: 1em 0;
}
.activities-list > li{
    font-size: 13px;
}
.div-active {
    display: inline-block;
}
.non-active-dot {
    margin-bottom: 0.15em;
    width: 4px;
    height: 4px;
    background-color: rgb(240, 105, 15);
    border-radius: 50%;
}
.active-dot {
    margin-bottom: 0.15em;
    width: 4px;
    height: 4px;
    background-color: rgb(17, 194, 17);
    border-radius: 50%;
    animation: dotAnimation 2s infinite;
}

@keyframes dotAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.skills {
    display: grid;
    grid-template-columns: 1fr;
}
.skills > h3 {
    display: block;
    margin: 0 auto 0.3em auto;
    text-align: center;
    width: 200px;
    border-bottom: 2px solid var(--color-container);
    margin-bottom: 0.3em;
}
.skills-ul1{
/*     background-color: rgba(105, 105, 105, 0.1);
    box-shadow:inset 0px 0px 5px 0px var(--color-container); */
    margin-right: 0.5em;
}.skills-ul2{
/*     background-color: rgba(105, 105, 105, 0.1);
    box-shadow:inset 0px 0px 5px 0px var(--color-container); */
}
.skills-ul1 > li{
    text-align: center;
    padding: 0em 0.3em;
}
.skills-ul2 > li{
    text-align: center;
    padding: 0.3em 0.3em;
}
.webdev {
    width: 350px;
    margin: 2em auto;
}
.webdev-box{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.button {
    display: block;
    margin: 0 auto 2em auto;
    width: 100px;
    background-color: var(--color-blue);
    color: White;
    font-weight: bold;
    text-align: center;
    box-shadow: 0px 0px 5px var(--color-container);
    transition: opacity 250ms ease-out;
}
.button:hover {
    opacity: 0.8;
}
.salesforce > ul {
    text-align: center;
}
.certification {
    padding: 3em 0;

}
.certification > h3{
    display: block;
    margin: 0 auto 0.3em auto;
    text-align: center;
    width: 170px;
    margin-bottom: 0.3em;
}
.certification-ul > li {
    text-align: center;
}
.container-link-box {
    display: inline-block;
    margin: 1em;
}
.contacts{
    padding: 1em;
    margin: 2em auto;
    text-align: center;
}

/* =================================== */
@keyframes myAnim {
	0% {
		animation-timing-function: ease-out;
		transform: scale(1);
		transform-origin: center center;
	}

	10% {
		animation-timing-function: ease-in;
		transform: scale(0.91);
	}

	17% {
		animation-timing-function: ease-out;
		transform: scale(0.98);
	}

	33% {
		animation-timing-function: ease-in;
		transform: scale(0.87);
	}

	45% {
		animation-timing-function: ease-out;
		transform: scale(1);
	}
}
@keyframes example {
    0%   {opacity: 0;}
    10%  {opacity: 1;}
    11% {opacity: 0;}
    12% {opacity: 1;}
    68% {opacity: 1;}
    70%  {opacity: 0;}
    72%  {opacity: 1;}
    73%  {opacity: 0;}
    74%  {opacity: 1;}
    100%  {opacity: 1;}
}
@-webkit-keyframes example {
    0%   {opacity: 0;}
    10%  {opacity: 1;}
    11% {opacity: 0;}
    12% {opacity: 1;}
    68% {opacity: 1;}
    70%  {opacity: 0;}
    72%  {opacity: 1;}
    73%  {opacity: 0;}
    74%  {opacity: 1;}
    100%  {opacity: 1;}
}

@media screen and (min-width: 768px) {
    .cText {
        margin: 6em auto;
    }
    .info-container{
        grid-template-columns: 1fr 1fr;
        max-width: 900px;
    }
    iframe {
        width: 560px;
        height: 315px;
    }
    .block {
        width: 500px;
    }
}
@media screen and (min-width: 1000px) {
    .cText {
        margin: 6em auto;

    }
    .info-container{
        grid-template-columns: 1fr 1fr;
        max-width: 900px;
    }
    .skills {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .block {
        width: 650px;
    }
}
@media screen and (min-width: 1200px) {
    .block1 {
        margin: 0 auto;
        grid-template-columns: 1fr 1fr;
    }
    .info-container{
        max-width: 1400px;
    }
    .block > img {
        width: 350px;
        height: 350px;
    }
    .quick {
        position: fixed; 
        top: 4em;
        left: 6em;
    }
    #down{
        top: 5em;
    }

}